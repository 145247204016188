<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.userManagement')"
    id="userManagement"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button
          v-has="'编辑用户'"
          v-if="isEdit"
          class="fr"
          type="text"
          @click="setIsEdit('basicColumns', false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onSubmit"
          @resetForm="setIsEdit('basicColumns', true, initData)"
        ></jl-form>
      </div>
    </el-card>
    <el-card v-if="isEdit" class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.maintenanceAttr") }}</span>
        <el-button
          v-has="'编辑用户'"
          v-if="isEdit"
          class="fr"
          type="text"
          @click="setIsEdit('maintainColumns', false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="maintainColumns"
          @onSubmit="onSubmit"
          @resetForm="
            setIsEdit('maintainColumns', true, initData.maintenancePersonnelDTO)
          "
        ></jl-form>
      </div>
    </el-card>
    <el-card v-if="isEdit" class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.maintenanceLevelInfo") }}</span>
      </template>
      <div class="text item">
        <jl-table
          @handleDelete="handleDelete"
          @handleEdit="handleEdit"
          :tableData="brandData"
          :options="brandOptions"
          :columns="brandColumns"
          :operates="brandOperates"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "AddEditAuthorization",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      basicColumns: [
        {
          prop: "userName",
          label: "userName",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "nick",
          label: "nick",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "password",
          label: "password",
          type: "password",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "repassword",
          label: "confirmPassword",
          type: "password",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "role",
          label: "role",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "companyId",
          label: "companyName",
          type: "remote",
          readonly: true,
          props: { label: "name", value: "id" },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val);
          },
          change: (id) => {
            getAllotLevel(id);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "telephone",
          label: "mobilePhone",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "allotId",
          label: "group",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          readonly: true,
          change: (id) => {
            getUpper(id);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      maintainColumns: [
        {
          prop: "manager",
          label: "manager",
          type: "select",
          props: { label: "name", value: "name" },
          data: [],
          readonly: true,
        },
        // {
        //   prop: "region",
        //   label: "station",
        //   type: "input",
        //   readonly: true,
        // },
        {
          prop: "station",
          label: "station",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          readonly: true,
        },
      ],
      brandData: [],
      brandOptions: {
        isAdd: true,
        isEdit: false,
        isDelete: true,
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      brandColumns: [
        {
          prop: "elevatorBrandName",
          label: "elevatorBrand",
          align: "center",
          type: "select",
          props: { label: "name", value: null },
          data: [],
          change: (val) => {
            state.brandColumns[1].data = val.level;
          },
        },
        {
          prop: "levelName",
          label: "maintenanceLevel",
          align: "center",
          formatter: (row) => {
            return t("i18n." + row.name);
          },
          type: "select",
          props: { label: "code", value: null },
          data: [],
        },
      ],
      brandOperates: {
        width: 140,
        fixed: "right",
        list: [],
      },
      detail: "",
      isEdit: false,
      initData: "",
    });

    const initData = () => {
      state.isEdit = route.query.flag == 0 ? false : true;
      if (state.isEdit) {
        init();
      } else {
        state.basicColumns.map((item) => {
          item.readonly = false;
        });
      }
      getAllRole();
      areaAttribute();
    };

    const getAllRole = async () => {
      // 角色下拉数据
      var index = state.basicColumns.findIndex((item) => {
        return item.prop === "role";
      });
      let { data } = await proxy.$api.system.getAllRole();
      state.basicColumns[index].data = data;
    };

    const areaAttribute = async () => {
      // 区域属性
      var index = state.maintainColumns.findIndex((item) => {
        return item.prop === "station";
      });
      let { data } = await proxy.$api.maintenance.areaAttribute();
      state.maintainColumns[index].data = data;
    };

    const getAllotLevel = async (id) => {
      var index = state.basicColumns.findIndex((item) => {
        return item.prop === "allotId";
      });
      let { data } = await proxy.$api.system.getAllotByCompany({
        companyId: id,
      });
      state.basicColumns[index].data = data;
    };

    const getUpper = async (id) => {
      var index = state.maintainColumns.findIndex((item) => {
        return item.prop === "manager";
      });
      let { data } = await proxy.$api.system.getUpperAllot(id);
      state.maintainColumns[index].data = data;
    };

    const init = async () => {
      if (route.query.id) {
        let id = route.query.id;
        let { data } = await proxy.$api.common.getUser(id);
        state.detail = JSON.parse(JSON.stringify(data));
        state.brandData = state.detail.repairLevelDTOList;
        getAllotLevel(data.companyId);
        getUpper(data.allotId);
        data.companyId = data.companyName;
        data.repassword = data.password;
        if (data.roleDTOList) {
          data.role = data.roleDTOList[0].id;
        }
        state.initData = data;
        setIsEdit("basicColumns", true, data);
        setIsEdit("maintainColumns", true, data.maintenancePersonnelDTO);
      }
    };

    const onSubmit = (data) => {
      // 编辑基础信息
      let row = JSON.parse(JSON.stringify(data));
      if (row.password === row.repassword) {
        if (route.query.flag == 1 && route.query.id) {
          editFunc(row);
        } else {
          row.roleDTOList = [];
          row.roleDTOList.push({ id: row.role });
          row.maintenancePersonnelDTO = { name: row.nick };
          proxy.$api.system.addUser(row).then(() => {
            proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
            setTimeout(() => {
              router.go(-1);
            }, 500);
          });
        }
      } else {
        proxy.$defined.tip(t("i18n.passwordDiffer"));
      }
    };

    const editFunc = async (row) => {
      delete row.companyId;
      for (let key in row) {
        state.detail[key] = row[key];
      }
      if (row.role) {
        state.detail.roleDTOList[0].id = row.role;
      }
      if (Object.keys(row).includes("station")) {
        state.detail.maintenancePersonnelDTO.manager = row.manager || "";
        state.detail.maintenancePersonnelDTO.region = row.region || "";
        state.detail.maintenancePersonnelDTO.station = row.station || "";
      }
      await proxy.$api.system.editUser(state.detail);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      init();
    };

    const remoteCompanyMethod = (val) => {
      var index = state.basicColumns.findIndex((item) => {
        return item.prop === "companyId";
      });
      if (val) {
        state.basicColumns[index].loading = true;
        setTimeout(() => {
          proxy.$api.system.getCompanyByPerson(val).then((data) => {
            state.basicColumns[index].data = data.data;
            state.basicColumns[index].loading = false;
          });
        }, 1000);
      } else {
        state.basicColumns[index].data = [];
      }
    };

    const getBrandList = async () => {
      var index = state.brandColumns.findIndex((item) => {
        return item.prop === "elevatorBrandName";
      });
      const { data } = await proxy.$api.system.getBrandLevel();
      state.allBrand = data;
      let arr = [];
      data.map((item, i) => {
        item.brand.index = i;
        item.brand.level = item.level;
        arr.push(item.brand);
      });
      state.brandColumns[index].data = arr;
    };
    getBrandList();

    const setStatus = async (id, status) => {
      await proxy.$api.system.setRescueStatus(id, status);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      router.go(-1);
    };

    const setIsEdit = (columns, flag, data) => {
      state[columns].map((item, index) => {
        if (
          item.prop != "companyId" &&
          item.prop != "nick" &&
          item.prop != "userName"
        ) {
          item.readonly = flag;
        }
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
            state[columns][index].value = data[item.prop];
          }
        }
      });
    };

    const handleEdit = (val) => {
      // 新增/编辑数据
      if (val.elevatorBrandName instanceof Object) {
        val.elevatorBrandId = val.elevatorBrandName.id;
        val.elevatorBrandName = val.elevatorBrandName.name;
      }
      if (val.levelName instanceof Object) {
        val.id = val.levelName.id;
        val.name = val.levelName.code;
      }
      let index = state.detail.repairLevelDTOList.findIndex((item) => {
        return item.id === val.id;
      });
      if (index == -1) {
        state.detail.repairLevelDTOList.push(val);
      } else {
        for (let key in val) {
          state.detail.repairLevelDTOList[index][key] = val[key];
        }
      }
      editFunc([]);
    };

    const handleDelete = (row) => {
      let index = state.detail.repairLevelDTOList.findIndex((item) => {
        return item.id === row.id;
      });
      state.detail.repairLevelDTOList.splice(index, 1);
      editFunc([]);
    };

    initData();

    return {
      ...toRefs(state),
      init,
      setIsEdit,
      handleDelete,
      setStatus,
      handleEdit,
      remoteCompanyMethod,
      getAllotLevel,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#userManagement {
  .form-button,
  .form-button .el-form-item__content {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
}
</style>
